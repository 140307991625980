import React from "react";
import "./protocol-modal.less"
import {CenterPopup} from "antd-mobile";
import FrameLoader from "./FrameLoader";
import protocol from "./protocol.json"

type ProtocolFileType = "PrivacyPolicy" | "RegisterAgreement" | "RegisterAgreementMiFeng" | "MustKnow" | "InformationAuthorization" | "Authorization" |"LOGIN"|"JkyqPolicy"|"HongYuanPrivacyPolicy"|"HongYuanRegisterAgreement"|"HongYuanMustKnow"|"HongYuanAuthorization";
type  ProtocolModalProps = {
    onClose: () => void;
    fileList: ProtocolFileType[]
}
const LoginFile = ["PrivacyPolicy", "RegisterAgreement", "MustKnow", "InformationAuthorization","HongYuanPrivacyPolicy","HongYuanRegisterAgreement","HongYuanMustKnow","HongYuanAuthorization"]
export default class ProtocolModal extends React.Component<ProtocolModalProps, any> {


    constructor(props: ProtocolModalProps) {
        super(props);
        const {fileList} = this.props;
        this.state = {
            url: "",
            title: "",
            visible: false,
            fileList: fileList.indexOf("LOGIN") === -1 ? fileList : LoginFile
        }
    }

    componentDidUpdate(prevProps: Readonly<ProtocolModalProps>, prevState: Readonly<any>, snapshot?: any) {
        const {fileList} = this.props;
        if (fileList !== prevProps.fileList) {
            this.setState({fileList: fileList.indexOf("LOGIN") === -1 ? fileList : LoginFile})
        }
    }


    render() {

        const {onClose} = this.props;
        const {url, title, visible, fileList = []} = this.state;
        return <>

            {
                fileList.map((p: string, index: number) => {
                        // @ts-ignore
                        const data = protocol[p]
                        return <span key={index} onClick={() => {
                            this.setState({visible: true, title: data.title, url: data.url})
                        }}>《{data.title}》</span>
                    }
                )
            }

            <CenterPopup
                mask={true}
                visible={visible}
                onClose={() => {
                    this.setState({visible: false})
                    onClose()
                }}
                bodyClassName={"flash"}
                showCloseButton={true}
                style={{"--min-width": "88vw"}}
            >
                <div className={"title"}>{title}</div>
                <FrameLoader url={url}/>
                <div className={'btn'}>
                    <span>点击同意并继续，即视您阅读并同意上述协议</span>
                    <button onClick={() => {
                        this.setState({visible: false})
                        onClose()
                    }}>同意并继续
                    </button>
                </div>
            </CenterPopup>
        </>
    }
}

