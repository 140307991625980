import React, {LegacyRef} from "react";
import {Dialog} from "antd-mobile";

type ConfirmProps = {
    children?: React.ReactNode;
    title?: string;
    content: string;
    visible?: boolean;
    cancelText?: string;
    successText?: string;
    clickRef?: LegacyRef<HTMLDivElement> | undefined;
    onCancel?: () => void;
    onSuccess?: () => void;
}


export default class Confirm extends React.Component<ConfirmProps, any> {


    showModal() {

    }

    render() {
        const {
            title = "提示",
            content,
            cancelText = "取消",
            successText = "确认",
            onCancel,
            onSuccess,
            children,
            clickRef
        } = this.props;
        return (
            <div ref={clickRef} onClick={() => {

                Dialog.show({
                    title: title,
                    content: content,
                    closeOnAction: true,
                    actions: [[
                        {
                            style: {color: "#7c7c7c"},
                            key: 'cancel',
                            text: cancelText,
                            onClick: onCancel
                        },
                        {
                            key: 'success',
                            text: successText,
                            onClick: onSuccess
                        },
                    ]],
                })
            }}>
                {
                    children
                }
            </div>
        );
    }

}
