// @ts-ignore
import md5 from "md5"

// @ts-ignore
import CryptoJS from 'crypto-js'
import loginUser from "@/store/loginUser";

const evenRightCharTable = ["A", "V", "e", "k", "r", "r", "K", "D", "d", "t"]
// 偶数左
const evenLeftCharTable = ["S", "u", "o", "M", "e", "a", "t", "i", "d", "G"]
// 奇数右
const oddRightCharTable = ['C', 'U', 'I', 'b', 'Q', 'Q', 'r', 's', 'a', 'F']
// 奇数左
const oddLeftCharTable = ["L", "f", "Z", "f", "p", "S", "O", "V", "N", "Q"]

//DES加密


export function GetSign() {
    const timestamp = new Date().getTime() + ""
    const timesArr: string[] = timestamp.split("")
    let leftKeyword: string[]
    let rightKeyword: string[]
    if (0 === Number(timesArr[6]) % 2) {
        leftKeyword = evenLeftCharTable
        rightKeyword = evenRightCharTable
    } else {
        leftKeyword = oddLeftCharTable
        rightKeyword = oddRightCharTable
    }
    const arr = timesArr.map((p, i) => i < 6 ? leftKeyword[Number(p)] : i > 6 ? rightKeyword[Number(p)] : p)

    arr.splice(6, 1)
    const text = arr.join("") + "&" + timestamp;
    return {sign: md5(text), text: text, timestamp: timestamp}
}


export function encryptAes(token: string | undefined, data: string): string {
    const key16 = CryptoJS.enc.Utf8.parse(token?.substring(0, 16));
    let result = CryptoJS.AES.encrypt(encodeURI(data), key16, {
        mode: CryptoJS.mode.ECB,//加密模式
        padding: CryptoJS.pad.Pkcs7 //填充
    });

    return CryptoJS.enc.Base64.stringify(result.ciphertext);
}

export function encryptPhone(data: string): string {
    const key16 = CryptoJS.enc.Utf8.parse(loginUser.channelToken?.substring(0, 16));
    let result = CryptoJS.AES.encrypt(encodeURI(data), key16, {
        mode: CryptoJS.mode.ECB,//加密模式
        padding: CryptoJS.pad.Pkcs7 //填充
    });

    return CryptoJS.enc.Base64.stringify(result.ciphertext);
}


