import {Redirect, Route, Switch} from 'react-router-dom';
import React, {useMemo} from 'react';
import Global from '../config/global';
import Constant from '../config/global';
import MenuElementMapping, {MenuElement} from "./router-parse";
import Error404 from "../layout/error404";
import Error501 from "../layout/error501";
import {LoaderLayoutPage, LoaderPage, NonePage} from "./router-loader";

export default function RouterApp() {


    const router = useMemo(() => {
        const router = [];
        const entries = MenuElementMapping.mapping.entries()
        router.push(<Redirect key={Math.random()} exact from={'/'} to={Constant.IndexPage}/>);

        while (true) {
            const {value, done} = entries.next()
            if (done) {
                break
            }
            const menuElement: MenuElement = value[1]

            if (menuElement.type === "Bar") {
                router.push(<Route key={Math.random()} exact path={menuElement.path}
                                   component={LoaderLayoutPage(menuElement)}/>);
            } else if (menuElement.type === "Link") {
                router.push(<Route key={Math.random()} exact path={menuElement.path}
                                   component={LoaderPage(menuElement)}/>);
            } else {
                router.push(<Route key={Math.random()} exact path={menuElement.path}
                                   component={NonePage(menuElement)}/>);
            }
        }
        router.push(<Route key={Math.random()} exact path={'/404'} component={Error404}/>);
        router.push(<Route key={Math.random()} exact path={'/501'} component={Error501}/>);
        router.push(<Route key={Math.random()} exact path={'*'} component={Error404}/>);


        return router;
    }, []);


    return <Global.RouterType>
        <Switch>
            {router}
        </Switch>
    </Global.RouterType>;
}

