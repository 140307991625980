import { CenterPopup, Button } from "antd-mobile";
import './product-apply.less'
import ProductController from "@/api/product-controller";
import React, { useCallback, useEffect, useState } from "react";
import PageLoading from "@/component/PageLoading";
import * as H from "history"
import LoginUser from "@/store/loginUser";
import { OpenProduct } from "@/common/utils";
import ProtocolModal from "@/component/protocol-modal";
import tips from "@/common/tips";
import ProductExit from "@/component/ProductExit";


export type ProductType = {
    id: string;
    productName: string;
    showDetail: boolean;
    strMinLoan: string;
    strMaxLoan: string;
    maxMonth: number;
    minMonth: number;
}
type ProductApplyProps = {
    data?: ProductType;
    toIndex?: boolean;
    history: H.History;
    unclose?: boolean;
    onClose: () => void;

}

export default function ProductApply(props: ProductApplyProps) {
    const { data, onClose, unclose, history } = props;
    const [loading, setLoading] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [checked, setChecked] = useState(true)
    const [agreementVisible, setAgreementVisible] = useState(false) // 吉客勾选协议
    const editClose = useCallback(() => {
        setShowDetail(false)
        onClose()
    }, [onClose])

    useEffect(() => {
        if (data) {
            // @ts-ignore
            const { id, showDetail } = data;
            if (id === '1645712845071060992') {
                setAgreementVisible(true)
            } else if (showDetail) {
                setShowDetail(showDetail)
            } else {
                ProductController.toProduct({ productId: id }, {
                    setLoading,
                    success: (data) => {
                        // @ts-ignore
                        const { phone, url } = data;
                        editClose();
                        OpenProduct(history, { phone, linkUrl: url, id })
                    },
                })
            }


        }
    }, [data, editClose, setAgreementVisible,history])
    const doApply = useCallback(() => {
        if (!checked) {
            tips.error("请先同意授权协议")
        } else {
            // @ts-ignore
            const id = data.id
            ProductController.commitTo({ productId: id }, {
                setLoading,
                success: (data) => {
                    // @ts-ignore
                    const { phone, url } = data;
                    editClose();
                    OpenProduct(history, { phone, linkUrl: url, toIndex: props.toIndex, id })
                }
            })
        }

    }, [checked, data, editClose, history, props.toIndex])

    return <>
        <PageLoading loading={loading} />
        <ProductExit />
        <CenterPopup
            bodyStyle={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            showCloseButton={true}
            closeOnMaskClick={true}
            visible={showDetail}
            onClose={() => {
                if (unclose) {
                    doApply()
                }
                editClose()
            }}
        >
            <div className="product-popup-page">
                <div className="product-header">
                    <span>{data?.productName}</span>
                </div>

                <div className="product-top-cont">
                    <div className="titles">贷款额度:</div>
                    <div className="amount">{data?.strMinLoan}-{data?.strMaxLoan}</div>
                    <div className="time-limit">期限：{data?.minMonth}- {data?.maxMonth}个月</div>
                </div>
                <div className="product-bottom-cont">
                    <div className="explain">
                        本次借款服务由“机构”提供，本平台仅提供信息
                        展示,不参与任何放款行为，借款过程中不会收取
                        任何费用。
                    </div>
                    <div className="agreement">
                        <div className="agreement-cont">
                            <div className="check">
                                <img src={checked ? require('./img/check-y.png') : require('./img/check-n.png')} alt=""
                                    onClick={() => setChecked(!checked)} />
                            </div>

                            <div className="cont">
                                我已阅读并同意如下
                                <span>
                                    <ProtocolModal fileList={ LoginUser.channelCode === 'IHAgbY' ? ["HongYuanAuthorization"]:["Authorization"]} onClose={() => {
                                        setChecked(true)
                                    }} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="apply-immediately" onClick={doApply}>
                        立即申请
                    </div>
                    {/* <div className="apply-immediately-no" >
            立即申请
          </div> */}
                </div>
            </div>
        </CenterPopup>
        <CenterPopup
            closeOnMaskClick={true}
            visible={agreementVisible}
            onClose={() => {
                setAgreementVisible(false) 
                editClose()
            }}
            showCloseButton={true}
        >
            <div className="agreemen">
                <div className="tits">服务协议</div>
                <div className="cont">尊敬的用户，为您提供更多服务前请先阅读并同意下列协议。</div>
                <div className="MustKnow">
                    <ProtocolModal  fileList={["JkyqPolicy"]} onClose={() => {}} />
                </div>
                <Button block color='primary' shape='rounded' onClick={() => {
                    setTimeout(() => {
                        // @ts-ignore
                        const id = data.id
                        ProductController.commitTo({ productId: id }, {
                            setLoading,
                            success: (data) => {
                                // @ts-ignore
                                const { phone, url } = data;
                                setAgreementVisible(false)
                                editClose();
                                OpenProduct(history, { phone, linkUrl: url, toIndex: props.toIndex, id })
                            }
                        })
                    }, 500)
                }}>
                    授权并申请
                </Button>

            </div>
        </CenterPopup>
    </>
}
