import React, {CSSProperties} from 'react';

type  FrameLoaderProps = {
    url?: string;
    style?: CSSProperties;
}

export default class FrameLoader extends React.Component<FrameLoaderProps> {


    render() {
        let iframeStyle = {width: '100%', height: '70vh', overflow: "scroll", ...this.props?.style}


        const result = this.props.url ? <iframe title={"myIframe"} id={"myIframe"} style={iframeStyle}
                                                sandbox="allow-scripts allow-downloads allow-same-origin allow-top-navigation-to-custom-protocols allow-top-navigation-by-user-activation allow-top-navigation allow-popups"
                                                loading={"lazy"}
                                                src={this.props.url}
                                                frameBorder='0'
        /> : <></>
        return <>
            {
                result
            }
        </>;

    }
}

