// @ts-ignore
import md5 from "md5"
import {OpenWeb501} from "../common/utils";

const loginUserCacheKey = "ls_mifeng"
const loginUserSecretKey = "ls_mifeng_token"
const loginUserTokenSecret = "asdfasdfqaewrf34trqrgjk$%@$^$%&^#%^!31gfdgasdada"

export class User {
    token: string | undefined;
    channelToken: string | undefined;
    channelCode: string | undefined;
    onlyInto: boolean | undefined;
    mobile: string | undefined;
    into: boolean | undefined;
    exists: boolean | undefined;
    HongYuanCity: boolean | undefined; //HongYuanCity为true 时不显示后端产品 code=IHAgbY 屏蔽城市  东莞   重庆   成都 南京 
    constructor() {
        this.exists = false;

    }

    /**
     * 清除缓存
     */
    clear(): string {
        const channelCode = this.channelCode;
        this.exists = false;
        this.channelToken = undefined;
        this.channelCode = undefined;
        this.onlyInto = undefined;
        this.mobile = undefined;
        this.into = undefined;
        this.HongYuanCity = undefined;
        localStorage.removeItem(loginUserCacheKey);
        localStorage.removeItem(loginUserSecretKey);
        return channelCode || "testCode";
    }

    /**
     * 同步缓存
     */
    storage() {
        const str = JSON.stringify(this)
        const sign = md5(str + "&" + loginUserTokenSecret)
        localStorage.setItem(loginUserCacheKey, str);
        localStorage.setItem(loginUserSecretKey, sign);

    }

    /**
     * 修改登录信息
     * @param data
     */
    save(data: User | {}) {
        // @ts-ignore
        Object.keys(data).forEach(p => this[p] = data[p])
    }

    /**
     * 修改登录信息并且保存
     * @param data
     */

    saveAndFlush(data: User | {}) {
        // @ts-ignore
        Object.keys(data).forEach(p => this[p] = data[p])
        if (this.mobile) {
            this.mobile = this.mobile.substring(0, 3) + "****" + this.mobile.substring(7, 11)
        }
        this.storage()
    }

    /**
     * 懒加载获取User
     */
    sync(): User {
        if (this.exists) {
            return this;
        } else {
            this.reload()
            return this;
        }
    }

    /**
     * 重载User
     */
    reload() {
        const str = localStorage.getItem(loginUserCacheKey);
        if (str && str !== "") {
            const {
                token = undefined,
                channelToken = undefined,
                channelCode = undefined,
                onlyInto = undefined,
                into = undefined,
                mobile = undefined,
                HongYuanCity = undefined,
            } = JSON.parse(str)
            const sign = md5(str + "&" + loginUserTokenSecret)
            if (sign === localStorage.getItem(loginUserSecretKey)) {
                this.token = token;
                this.channelToken = channelToken;
                this.channelCode = channelCode;
                this.onlyInto = onlyInto;
                this.into = into;
                this.mobile = mobile;
                this.exists = true;
                this.HongYuanCity = HongYuanCity
            } else {
                this.clear()
                OpenWeb501()
            }

        }
    }
}

const LoginUser: User = new User().sync();
export default LoginUser;

