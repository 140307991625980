import { NavBar } from "antd-mobile";
import { ElementChildrenProps } from "@/component/type";
import Confirm from "../component/Confirm";
import { useMemo, useRef, useState } from "react";
import Constant from "../config/global";
import { OpenWeb } from "@/common/utils";
import { CloseOutline } from 'antd-mobile-icons'
import * as H from "history";
import ProductController from "@/api/product-controller";
import ProductApply, { ProductType } from "@/pages/index/product-apply";
import tips from "@/common/tips";
import AppDetect from "@/common/app-detect";
import PageLoading from "@/component/PageLoading";
import LoginUser from "@/store/loginUser";
import MemberController from "@/api/member-controller";

export default function INavBar(props: ElementChildrenProps) {
    const { element, history } = props;

    const [loading, setLoading] = useState(false)
    const [productData, setProductData] = useState<ProductType | undefined>()

    const confirmRef = useRef(null)
    const titleName = useMemo(() => {
        // @ts-ignore
        return window.titleMapper.getTitleName("");

    }, [element.name])

    const [leftText, rightText] = useMemo(() => {
        if (isInformation(history)) {
            return ["查看其他", "继续申请"]
        } else {
            return ["狠心离开", "查看其他"]
        }
    }, [history])


    const topRouter = ProductController.useTopRouter({
        setLoading,
        success: (data) => {
            // @ts-ignore
            const { productId, showDetail, phone, linkUrl } = data;
            if (showDetail) {
                setProductData({ ...data as ProductType, id: productId })
            } else {
                tips.awaitSuccess("暂无推荐产品")
            }
        },
    })

    const cancelPage = async () => {
        if (LoginUser.onlyInto) {
            return
        }
        const cancelCount = localStorage.getItem("cancelNumber") || "1"
        if (!(LoginUser.token || LoginUser.sync().token)) {
            const res = await MemberController.asyncTemporaryUser("xxxxxxxxxxx")
            const { info, license, onlyInto } = res.data;
            LoginUser.saveAndFlush({
                mobile: "xxxxxxxxxxx",
                into: info,
                onlyInto,
                token: license
            })
        }
        // @ts-ignore
        switch (cancelCount) {
            case "1":
                localStorage.setItem("cancelNumber", "2")
                topRouter({ weChat: false, clientShowDetail: true })
                break
            case "2":
                localStorage.setItem("cancelNumber", "3")
                // @ts-ignore
                confirmRef.current.click()
                break
            case "3":
                OpenWeb(history, Constant.IndexPage, { toIndex: true })
                break
        }
    }

    const LoginPages = ['/login', '/haohuijie', '/mifengLogin', '/huanbei', '/rong360', '/shengbei', '/xiaohua', '/loginV1', '/loginV2', '/loginV3']

    const backShow = () => {
        if (LoginUser.sync().HongYuanCity || LoginPages.includes(history.location.pathname)) {
            return true
        }else {
            return false
        }
    }


    return <>
        <PageLoading loading={loading} />
        <ProductApply data={productData} history={props.history}
            onClose={() => {
                setProductData(undefined)
            }} toIndex={false} />
        <Confirm clickRef={confirmRef} title={"客官请留步"} content={"完成申请额度轻松拿"}
            cancelText={leftText} successText={rightText}
            onCancel={() => {
                OpenWeb(history, Constant.IndexPage, { toIndex: true })
            }}
        />
        {/*<NavBar style={{
            display: backShow() ? "none" : "flex"
        }}
            className={"top"}
            left={<CloseOutline style={{ fontSize: 26 }} onClick={() => {
                cancelPage()

            }} />}
            onBack={() => {
                cancelPage()
            }}>{titleName}</NavBar>*/}


    </>
}

function isInformation(history: H.History): boolean {
    return history.location.pathname === "/information"
}
