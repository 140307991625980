import httpClient, {HttpUseProps, RespModel} from "../common/request";
import Constant from "../config/global";
import {useEffect} from "react";
import AppDetect from "../common/app-detect";
import tips from "../common/tips";
import LoginUser from "../store/loginUser";
import {OpenWeb501, OpenWebLogin} from "../common/utils";
import * as H from "history"
import {encryptPhone} from "@/common/sign";

type memberController = {
    flushCode: (phone: string) => Promise<RespModel<{ info: boolean, license: string, onlyInto: boolean }>>;
    login: (params: any, props: HttpUseProps) => void;
    temporaryUser: (mobile: any, props: HttpUseProps) => void;
    asyncTemporaryUser: (mobile: any) => Promise<any>;
    cancelAccount: (props: HttpUseProps) => void;
    useJoinLogin: (history: H.History, reload: () => void) => void;
}


const MemberController: memberController = {
    /**
     * 发送验证码
     */
    flushCode(phone: string) {
        return httpClient.post(Constant.BaseUrl + "/loan-web/flashCode", {phone: encryptPhone(phone)})
    },

    login(params: any, props: HttpUseProps) {
        params["phone"] = encryptPhone(params["phone"]);
        httpClient.doPost(Constant.BaseUrl + "/loan-web/login", params, props)
    },
    temporaryUser(mobile: any, props: HttpUseProps) {
        httpClient.doPost(Constant.BaseUrl + "/loan-web/temporaryUser", {mobile: encryptPhone(mobile)}, props)
    },
    async asyncTemporaryUser(mobile: any) {
        return await httpClient.post(Constant.BaseUrl + "/loan-web/temporaryUser", {mobile: encryptPhone(mobile)})
    }


    , cancelAccount(props: HttpUseProps) {
        httpClient.doPost(Constant.BaseUrl + "/loan-web/app/cancel", {}, props)
    }
    , useJoinLogin(history, reload) {
        useEffect(() => {
            const params = new URLSearchParams(window.location.search)
            const code = params.get("code");
            if (code) {
                const failure = () => tips.asyncError("登录信息不正确", false, () => {
                    LoginUser.clear()
                    window.location.href = "/501"
                })
                const {osId, osName} = AppDetect.os()
                httpClient.doPost(Constant.BaseUrl + "/loan-web/joint/login", {code, osId, osName}, {
                    success: (data) => {
                        // @ts-ignore
                        const {info, onlyInto, license, phone} = data;
                        LoginUser.sync().saveAndFlush({into: info, onlyInto, token: license, mobile: phone})
                        reload?.()
                    }, failure,
                    successNotZero: failure

                })
            } else if (!LoginUser.sync().token) {
                if (LoginUser.channelCode) {
                    OpenWebLogin()
                } else {
                    OpenWeb501()
                }
            } else {
                reload?.()
            }

        }, [history, reload])
    }
}
export default MemberController;
