import {MenuElement} from "./router-parse";
import React, {useEffect} from "react";
import AdminProgress from "../common/progress";
import INavBar from "../layout/INavBar";
import ITabPage from "../layout/ITabPage";
import Global from "./global";
import loginUser from "../store/loginUser";
import {OpenWeb501, OpenWebLogin} from "../common/utils";

export function LoaderPage(element: MenuElement): React.ComponentType {
    const children = (props: any) => {
        CheckAuth(element)
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<>   <AdminProgress/></>}>
            <INavBar {...props} element={element}/>
            <Comp {...props} element={element}/>

        </React.Suspense>
    }
    return children
}

export function NonePage(element: MenuElement): React.ComponentType {
    const children = (props: any) => {
        CheckAuth(element)
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<>   <AdminProgress/></>}>
            <Comp {...props} element={element}/>
        </React.Suspense>
    }
    return children
};

export function LoaderLayoutPage(element: MenuElement): React.ComponentType {
    const children = (props: any) => {
        CheckAuth(element)
        const Comp = React.lazy(() => import(`../${element.element}`));
        return <React.Suspense fallback={<><AdminProgress/></>}>
            <ITabPage {...props} element={element}>
                <Comp  {...props} element={element}/>
            </ITabPage>
        </React.Suspense>
    }
    return children
};


export function CheckAuth(element: MenuElement) {
    useEffect(() => {
        if (element && Global.CheckAuth) {
            if (element && !element.unLogin) {
                if (!loginUser.token) {
                    if (loginUser.channelToken || loginUser.channelCode) {
                        OpenWebLogin()
                    } else {
                        OpenWeb501()
                    }
                }

            }
        }
    }, [element])

}
