import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import Global from "../config/global";
import Constant from "../config/global";
import loginUser from "../store/loginUser";
import tips from "./tips";
import {useCallback} from "react";
import {GetSign} from "./sign";

const request: AxiosInstance = axios.create({
    timeout: 5000,
    withCredentials: false,
});
request.defaults.timeout = 15000;

//拦截请求
request.interceptors.request.use((config) => {
    if (!config.headers) config.headers = {};

    if (config.method === 'post' && config.data) {
        config.data = JSON.stringify(config.data)
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    if (loginUser.token) {
        config.headers["license"] = loginUser.token;
    }
    if (loginUser.channelToken) {
        config.headers["channel-token"] = loginUser.channelToken;
    }
    config.headers["serviceId"] = Constant.ServiceId;
    config.headers["type"] = 'md5';

    const {sign, timestamp} = GetSign()
    config.headers["md5"] = sign;
    config.headers["timestamp"] = timestamp;

    return config;
});
//拦截响应
request.interceptors.response.use((response: any) => {
        if (response.data.code === 2) {
            // tips.asyncError("请求失败，请稍后再试")
        } else if (response.data.code === 1) {
            tips.asyncError("请求失败，请稍后再试")
        }
        if (response.data.msg === "token is fail") {

            setTimeout(() => {
                if (loginUser.exists) {
                    const channelCode = loginUser.sync().channelCode;
                    loginUser.clear()
                    window.location.href = Global.LoginPage + "?code=" + channelCode;
                }
            }, 200)
        }
        return response.data;
    }, function (error: { code: string; response: { status: number; data: { error: any; }; }; }) {
        console.error(error)
        if (error.code === "ECONNABORTED") {
            tips.asyncError("服务繁忙，请稍等一分钟");
        } else if (error.code === "ERR_NETWORK") {
            tips.asyncError("服务繁忙，请稍等一分钟");
        } else if (error.response.status === 401) {
            tips.asyncError("用户信息验证失败，1s后跳转登陆页!");
            setTimeout(() => {
                window.location.href = Global.LoginPage + "?code=" + loginUser.clear();
            }, 1000)
        } else if (error.code === "ERR_BAD_REQUEST") {
            tips.asyncError("客户端请求信息错误");
        } else if (error.response.status === 500) {
            const errorData = error.response.data.error;
            console.error(errorData)
            tips.asyncError("服务端异常")
        }
        //对响应的错误做点什么
        return Promise.reject(error);
    },
);
export type HttpUseProps = {
    setLoading?: (val: boolean) => void;
    success?: HttpSuccessFun<any>;
    interceptor?: () => boolean;
    successNotZero?: HttpSuccessFun<any>;
    failure?: () => void;
    final?: () => void;
}
export type HttpSuccessFun<V> = (data: V) => void;
export type HttpUseHandler<V> = (param: V) => void;


class HttpClient {
    useGet(url: string, props: HttpUseProps): HttpUseHandler<any> {
        return () => {
        }
    }

    usePost(url: string, props: HttpUseProps): HttpUseHandler<any> {
        return () => {
        }
    }

    doPost(url: string, params: any, props: HttpUseProps) {
        const {setLoading, success, successNotZero, failure, final, interceptor} = props;
        if (interceptor === undefined || interceptor()) {
            setLoading?.(true)
            httpClient.post(url, params).then((res: any) => {
                if (res.code === 0) {
                    success?.(res.data)
                } else {
                    successNotZero?.(res.data)
                }
            }).catch(() => {
                failure?.()
            }).finally(() => {
                setLoading?.(false)
                final?.();
            })
        }

    }

    doGet(url: string, params: any, props: HttpUseProps) {
        const {setLoading, success, successNotZero, failure, final, interceptor} = props;
        if (interceptor === undefined || interceptor()) {
            setLoading?.(true)
            httpClient.urlGet(url, params).then((res: any) => {
                if (res.code === 0) {
                    success?.(res.data)
                } else {
                    successNotZero?.(res.data)
                }
            }).catch(() => {
                failure?.()
            }).finally(() => {
                setLoading?.(false)
                final?.();
            })
        }
    }

    post<T = any, R = RespModel<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return request.post(url, data, config)
    }

    urlGet<T = any, R = RespModel<T>>(url: string, params: { [x: string]: string | number | boolean; }): Promise<R> {
        const array = [];
        let keys = Object.keys(params);
        for (let i in keys) {
            let key = keys[i];
            const val = params[key];
            if (val !== undefined && val !== '') {
                array.push(key + "=" + encodeURIComponent(val))
            }
        }
        const urlParams = array.join("&")
        return request.get(url + (urlParams.length > 0 ? "?" + urlParams : ""))

    }


}

const httpClient = new HttpClient()

const usePost = (url: string, props: HttpUseProps): HttpUseHandler<any> => {
    const handler: HttpUseHandler<any> = useCallback((params: any) => {
        httpClient.doPost(url, params, props)
    }, [props, url])
    return handler;
}

const useGet = (url: string, props: HttpUseProps): HttpUseHandler<any> => {
    const handler: HttpUseHandler<any> = useCallback((params: any) => {
        httpClient.doGet(url, params, props)
    }, [props, url])
    return handler;
}

httpClient.useGet = useGet;
httpClient.usePost = usePost;

export type RespModel<V> = {
    code: number;
    msg: string;
    flag: boolean;
    data?: V;
}


export default httpClient;
