import {Badge, TabBar} from 'antd-mobile'
import {AppOutline, UserOutline,AppstoreOutline} from 'antd-mobile-icons'
import {useEffect, useMemo, useState} from "react";
import {ElementChildrenProps} from "../component/type";

import INavBar from "./INavBar";

const tabs = [
    {
        key: '/index',
        title: '首页',
        icon: <AppOutline/>,
        badge: Badge.dot,
    }, {
        key: '/whole',
        title: '全部',
        icon: <AppstoreOutline/>,
        badge: Badge.dot,
    },
    {
        key: '/my',
        title: '我的',
        icon: <UserOutline/>,
    },
]


export default function ITabPage(props: ElementChildrenProps) {
    const {children, element, history} = props;

    const [selectActive, setSelectActive] = useState("/")
    useEffect(() => {
        setSelectActive(element.path)
    }, [element.path])

    const itemChildren = useMemo(() => {
        return tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
        ))
    }, [])

    return <>
        <div className={"app"}>
            <INavBar {...props}/>
            <div className={"body"}>
                {children}
            </div>

            <TabBar className={"bottom"} safeArea activeKey={selectActive} onChange={(key) => {

                history.push(key)
            }
            }>
                {itemChildren}
            </TabBar>
        </div>


    </>
}

