import tips from "./tips";
import Constant from "../config/global";
import LoginUser, {User} from "../store/loginUser";
import * as H from "history";
import MemberController from "@/api/member-controller";
import ChannelEventListener from "@/common/channel-switch";

export function CurrentUrl(): string {
    return window.location.href
}

export function OpenWeb(history: H.History, url: string, state: { toIndex?: boolean }) {
    setTimeout(() => {
        if (state?.toIndex) {
            window.history.replaceState("", "", Constant.IndexPage)
        }
        history.push(url, state)
    }, 200)
}

export function OpenWeb501(code?: string | null) {
    tips.error("渠道或用户信息不正确", false, () => window.location.href = "/501?code=" + code)
}

export function OpenWebLogin() {
    tips.error("登录信息已失效", false, () => window.location.href = Constant.LoginPage)
}

export function SwitchPage(loginUser: User, history: H.History) {
    const {into, onlyInto, token,HongYuanCity} = loginUser;

    if (token) {
        if (into) {
            if (onlyInto) {
                history.push("/initialSuccess")
            } else {
                if (HongYuanCity) {
                    history.push("/initialSuccess")
                }else{
                     history.push(Constant.IndexPage)
                }
            }
        } else {
            history.push("/information")
        }
    } else {
        tips.error("登录信息已失效", false, () => window.location.href = Constant.LoginPage)
    }

}


export function ScrollGather() {
    setTimeout(function () {
        let count = 1;
        const interval = setInterval(() => {
            const oldScroll = document.getElementById('gather')?.scrollTop;
            document.getElementById('gather')?.scrollTo({top: (oldScroll || 0) + count++})

            if (oldScroll === document.getElementById('gather')?.scrollTop) {
                clearInterval(interval)
            }
        }, 1)

        setTimeout(function () {
            clearInterval(interval)
        }, 1000)

    }, 200);
}

export function OpenProduct(history: H.History, state: { phone?: string, linkUrl: string, toIndex?: boolean, id?: string }) {
    if (state.id) {
        localStorage.setItem("id", state.id + ',' + new Date().getTime())
    }
    setTimeout(() => {
        if (state.toIndex) {
            window.history.replaceState("", "", Constant.IndexPage)
        }
        window.location.href = state.linkUrl;
        // if (AppDetect.isIPhone()) {
        //     window.location.href = state.linkUrl;
        // } else {
        //
        //     history.push("/product/pages", {...state})
        // }

    }, 200)

}

export function monitorMobileInput(history: H.History,mobile: string, setMobile: (mobile: string) => void) {
    setMobile(mobile);
    if (mobile.length === 11){
        const hours = new Date().getHours()
        if (hours >= 21 || hours <=7){
            MemberController.temporaryUser(mobile,{
                success:(data)=> {
                    const { info, license, onlyInto } = data;
                    setTimeout(() => { // 等待loading关闭
                        LoginUser.saveAndFlush({ mobile: mobile, into: info, onlyInto, token: license })
                        SwitchPage(LoginUser, history)
                    }, 200)
                }
            })
        }
    }
}

