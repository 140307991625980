import httpClient, {HttpUseHandler, HttpUseProps} from "../common/request";
import Constant from "../config/global";
import AppDetect from "@/common/app-detect";

type productController = {
    selectProductList: (props: HttpUseProps) => void;
    selectProductPage: (param: any, props: HttpUseProps) => void;
    toProduct: (param: any, props: HttpUseProps) => void;
    commitTo: (param: any, props: HttpUseProps) => void;
    useRecommend: (props: HttpUseProps) => HttpUseHandler<any>;
    useTopRouter: (props: HttpUseProps) => HttpUseHandler<any>;
    doFeedback:(param:any,props:HttpUseProps)=>void
}

const ProductController: productController = {
    selectProductList(props: HttpUseProps) {
        httpClient.doPost(Constant.BaseUrl + "/loan-web/product/list", {weChat:false}, props)
    }
    , selectProductPage(param: any, props: HttpUseProps) {
        httpClient.doPost(Constant.BaseUrl + "/loan-web/product/page", param, props)
    }
    , toProduct(param: any, props: HttpUseProps) {
        httpClient.doPost(Constant.BaseUrl + "/loan-web/product/toProduct", param, props)
    }
    , commitTo(param: any, props: HttpUseProps) {
        httpClient.doPost(Constant.BaseUrl + "/loan-web/product/commitTo", param, props)
    }
    , useRecommend(props: HttpUseProps): HttpUseHandler<any> {
        return httpClient.usePost(Constant.BaseUrl + "/loan-web/product/aiRecommend", props)
    }
    , useTopRouter(props: HttpUseProps): HttpUseHandler<any> {
        return httpClient.usePost(Constant.BaseUrl + "/loan-web/product/topRoute", props)
    }
    ,doFeedback(param:any,props:HttpUseProps){
       return  httpClient.doPost(Constant.BaseUrl+"/loan-web/product/submitFeedback",param,props)
    }
}

export default ProductController;
