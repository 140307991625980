import React from "react";
import {Modal} from "antd-mobile";
import ProductController from "../api/product-controller";
import PageLoading from "./PageLoading";
import "./ProductExit.less"

type  ProductExitProps = {
}

export default class ProductExit extends React.Component<ProductExitProps, any> {
    constructor(props: ProductExitProps) {
        super(props)
        this.state = {id: undefined, stat: false, loading: false}
    }


    componentDidMount() {
        let newTime = new Date().getTime()
        let oldIdTime = localStorage.getItem("id")
        if (oldIdTime) {
            let old = oldIdTime.split(",")
            let id = old[0]
            let oldTime = old[1]
            let stat = newTime - Number(oldTime) < 5000
            this.setState({id, stat})
            localStorage.removeItem("id")
        }
    }

    doFeedback(feedback: string) {
        const setLoading = (loading: boolean) => {
            this.setState({loading})
        }
        ProductController.doFeedback({productId: this.state.id, feedback}, {
            setLoading,
            success: (data) => {
                this.setState({stat: false})
            }
        })
    }

    render() {
        return <>
            <PageLoading loading={this.state.loading}/>
            <Modal
                bodyClassName={"boxModal"}
                visible={this.state.stat}
                content='是什么导致您没有继续申请该产品？'
                closeOnAction
                onClose={() => {
                }}
                actions={[
                    {
                        key: '产品不合适',
                        text: '产品不合适',
                        onClick: () => this.doFeedback('产品不合适'),
                    },
                    {
                        key: "只是随便看看",
                        text: '只是随便看看',
                        onClick: () => this.doFeedback('只是随便看看')
                    },
                    {
                        key: '链接打不开',
                        text: '链接打不开',
                        onClick: () => this.doFeedback('链接打不开')
                    },
                    {
                        key: '无法下载',
                        text: '无法下载',
                        onClick: () => this.doFeedback('无法下载')
                    },
                    {
                        key: '页面点不动',
                        text: '页面点不动',
                        onClick: () => this.doFeedback('页面点不动')
                    }
                ]}
            />
        </>;
    }
}
