import React from "react";
import tips from "../common/tips";


type PageLoadingProps = {
    loading: boolean;
    text ?:string
    children?: React.ReactNode;
}

export default class PageLoading extends React.Component<PageLoadingProps, any> {

    /**
     * 初始化方法
     * @param props
     */
    constructor(props: PageLoadingProps) {
        super(props);
        if (this.props.loading) {
            this.state = {close:{close: tips.loading({text:props.text}).close}}
        } else {
            this.state = {close: undefined}
        }
    }

    /**
     * 组件时间监听器，当props发生修改时触发
     * @param prevProps 修改前的 props
     * @param prevState 修改前的 state
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<PageLoadingProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.loading !== this.props.loading) {
            this.refresh(this.props.loading)
        }
    }

    /**
     * 更新方法
     * @param loading
     */
    refresh(loading: boolean) {
        if (loading) {
            this.setState({close: tips.loading({text:this.props.text}).close})

        } else {
            this.state?.close()
            this.setState({close: undefined})
        }
    }

    /**
     * 组件加载方法 (因为当前为loading所以无需返回具体值)
     */
    render() {

        return <>

        </>;
    }
}
